import React from 'react';
import map from 'lodash/map';
import { stringify } from 'rebem-classname';

export default function FiltrosHeaderListado (props){
	const filtros = props.filtrosAplicados;
	const filtrosAMostrar = filtros.map((filtro, index)=>{
		const nombreEstado = map(filtro.estado,(a,b)=>{return b;});
		return <a onClick={()=>props.onRestablecerFiltro(filtro,props.onBuscar)} data-estado={nombreEstado} key={index}>{filtro.nombre}{ }</a>
	});
	const block = "FiltrosHeaderListado"; 
	if(filtrosAMostrar.length){
		return(
			<div className={stringify({block})}>
				{filtrosAMostrar}
				<a onClick={()=>props.onRestablecerFiltros(props.onBuscar)} className="eliminar">Eliminar todos</a>
			</div>
		);			
	} else {
		return null;
	}
}